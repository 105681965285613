import React from "react";

const Header = ({ prefix, title }) => {
  return (
    <div className="header">
      <div>
        {prefix} <span>{title}</span>
      </div>
    </div>
  );
};

export default Header;
