import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./page/Home";
import Products from "./page/Products";
import Facilities from "./page/Facilities";
import About from "./page/About";
import ContactUs from "./page/ContactUs";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/products" component={Products} />
        <Route path="/facilities" component={Facilities} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={ContactUs} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
