import React from "react";
import { Link } from "react-router-dom";
import { facilityList } from "../utils/facilityList";
import { allProducts } from "../utils/productList";

const Footer = () => {
  return (
    <footer className="section-colums">
      <div className="footer">
        <div className="container">
          <div className="contact-content">
            <div className="contact-eng">
              <div>
                <div className="menu-title">Products</div>
                <div className="footer-link" style={{ marginBottom: 32 }}>
                  {allProducts.map((data) => {
                    return (
                      <div key={data.type}>
                        <Link to={`/products#${encodeURIComponent(data.type)}`}>
                          {data.type}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
                <div className="menu-title">Facilities</div>
                <div className="footer-link">
                  {facilityList.map((data) => {
                    return (
                      <div key={data.type}>
                        <Link
                          to={`/facilities#${encodeURIComponent(data.type)}`}
                        >
                          {data.type}
                        </Link>
                      </div>
                    );
                  })}
                </div>

                <div className="menu-title divider">Company</div>
                <div className="footer-link">
                  <div>
                    <Link to={`/about`}>About</Link>
                  </div>
                  <div>
                    <Link to={`/contact`}>Contact Us</Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="menu-title">Office & Work Shop</div>
              <div className="contact-marketing">
                <div className="">
                  Jl Raya PU No. 109 <br />
                  Kp. Cinyosog RT. 001 / 02 <br />
                  Desa Burangkeng, Kecamatan Setu <br />
                  Kabupaten Bekasi 17324 <br />
                </div>

                <div className="google-maps">
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="https://www.google.com/maps/place/PT.+EMKA+JAYA+MANDIRI+01/@-6.3379048,107.0187704,15z/data=!4m5!3m4!1s0x0:0x5f360f9cfffdab6d!8m2!3d-6.3379048!4d107.0187704"
                  >
                    See in google maps
                  </a>
                </div>
              </div>
              <div className="divider">
                Tlp. <a href="tel:(021) 2210 0617">(021) 2210 0617</a> /{" "}
                <a href="tel:(021) 8261 8585">8261 8585</a>
                <div>Fax. (021) 2948 7932</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        Copyright © {new Date().getFullYear()} PT EMKA JAYA MANDIRI
      </div>
    </footer>
  );
};

export default Footer;
