import spm1 from "../assets/img/spm-1.png";
import spm2 from "../assets/img/spm-2.png";
import spm3 from "../assets/img/spm-3.png";
import spm4 from "../assets/img/spm-4.png";
import spm5 from "../assets/img/spm-5.png";
import spm6 from "../assets/img/spm-6.png";

import cs1 from "../assets/img/cs-1.png";
import cs2 from "../assets/img/cs-2.png";
import cs3 from "../assets/img/cs-3.png";
import cs4 from "../assets/img/cs-4.png";
import cs5 from "../assets/img/cs-5.png";
import cs6 from "../assets/img/cs-6.png";

import gj1 from "../assets/img/gj-1.png";
import gj2 from "../assets/img/gj-2.png";

import ie1 from "../assets/img/ie-1.png";
import ie2 from "../assets/img/ie-2.png";
import ie3 from "../assets/img/ie-3.png";

import ir1 from "../assets/img/ir-1.png";
import ir2 from "../assets/img/ir-2.png";
import ir3 from "../assets/img/ir-3.png";
import ir4 from "../assets/img/ir-4.png";

import pl1 from "../assets/img/pl-1.png";

import m1 from "../assets/img/m-1.png";
import m2 from "../assets/img/m-2.png";
import m3 from "../assets/img/m-3.png";
import m4 from "../assets/img/m-4.png";
import m5 from "../assets/img/m-5.png";
import m6 from "../assets/img/m-6.png";
import m7 from "../assets/img/m-7.png";
import m8 from "../assets/img/m-8.png";
import m9 from "../assets/img/m-9.png";
import m10 from "../assets/img/m-10.png";
import m11 from "../assets/img/m-11.png";
import m12 from "../assets/img/m-12.png";
import m13 from "../assets/img/m-13.png";
import m14 from "../assets/img/m-14.png";
import m15 from "../assets/img/m-15.png";

export const allProducts = [
  {
    type: "Special Purpose Machine (SPM)",
    images: [
      {
        name: "Assy Machine",
        url: spm1,
      },
      {
        name: "Set Insert Machine",
        url: spm2,
      },
      {
        name: "Rell Guide Vehicle",
        url: spm4,
      },
      {
        name: "Auto Pos Box Machine",
        url: spm3,
      },
      {
        name: "Pin Assy Machine",
        url: spm5,
      },
      {
        name: "Auto Filling and Capping Machine",
        url: spm6,
      },
    ],
  },
  {
    type: "Industrial Robotic & System Integrator",
    images: [
      {
        name: "Handling Robot",
        url: ir1,
      },
      {
        name: "Integrasi 2 Robot",
        url: ir2,
      },
      {
        name: "Robot Injection Plastic",
        url: ir3,
      },
      {
        name: "Auto Plug Power Cord",
        url: ir4,
      },
    ],
  },
  {
    type: "Gripper & Jig Fixture",
    images: [
      {
        name: "Gripper / Chuck Robot Takeout & Loading Part to Machine",
        url: gj1,
      },
      {
        name: "Inspection Jig Countur Part",
        url: gj2,
      },
    ],
  },
  {
    type: "Conveyor System",
    images: [
      {
        name: "Integrasi Conveyor & Robot 3 Axis",
        url: cs1,
      },
      {
        name: "Power Roller Conveyor",
        url: cs2,
      },
      {
        name: "Belt Conveyor",
        url: cs3,
      },
      {
        name: "Inclane Conveyor",
        url: cs5,
      },
      {
        name: "Modular & Chains Conveyor",
        url: cs4,
      },

      {
        name: "Handling Part",
        url: cs6,
      },
    ],
  },
  {
    type: "Instalasi Electric & Panel Control",
    images: [
      {
        name: "Instalasi Panel LVMDP",
        url: ie1,
      },
      {
        name: "Costum Panel System",
        url: ie2,
      },
      {
        name: "Instalasi Control Room ROV",
        url: ie3,
      },
    ],
  },
  {
    type: "Painting Line",
    images: [
      {
        name: "",
        url: pl1,
      },
    ],
  },
  {
    type: "Machining",
    images: [
      {
        name: "Nozle Gas",
        url: m1,
      },
      {
        name: "Valve Air Liquid",
        url: m2,
      },
      {
        name: "ABS Leak Test",
        url: m3,
      },
      {
        name: "Guide Oil Seal",
        url: m4,
      },
      {
        name: "",
        url: m5,
      },
      {
        name: "Impeler",
        url: m6,
      },
      {
        name: "Cover Silo Gula",
        url: m7,
      },
      {
        name: "Mixer Liquid",
        url: m8,
      },
      {
        name: "Mold Maker",
        url: m9,
      },
      {
        name: "Production Monitoring",
        url: m15,
      },
      {
        name: "Dies Maker",
        url: m14,
      },
      {
        name: "Moving Crane",
        url: m13,
      },
      {
        name: "Multi Drill Machine",
        url: m10,
      },
      {
        name: "Bucket Elevator",
        url: m11,
      },
      {
        name: "Rel Guide Conveyor Transfer",
        url: m12,
      },
    ],
  },
];
