import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import arrowRight from "../assets/img/arrow-right.svg";
import { allProducts } from "../utils/productList";
import lathe from "../assets/img/lathe.png";
import milling from "../assets/img/milling.png";
import machining from "../assets/img/machining.png";
import gear1 from "../assets/img/gear-1.png";
import gear2 from "../assets/img/gear-2.png";
import { customerList } from "../utils/customerList";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

const facilitiesHome = [
  {
    name: "Lathe Machine",
    url: lathe,
  },
  {
    name: "Milling Machine",
    url: milling,
  },
  {
    name: "Machining Machine",
    url: machining,
  },
];

const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <Navbar scroll />
      <section className="hero">
        <div className="brand">
          <div className="title">PT. EMKA JAYA MANDIRI</div>
          <div className="motto">Industrial and Automation Solution</div>
          <Link to="/products">
            <button>
              See all products
              <img src={arrowRight} alt="Go" />
            </button>
          </Link>
        </div>
      </section>
      <section className="section-about">
        <div className="container">
          <div className="section-title">
            ABOUT <span>EMKA JAYA MANDIRI</span>
          </div>
          <div className="about-content">
            <div>
              <div className="title">History</div>
              <div>
                PT. EMKA JAYA MANDIRI was founded in 2014, thanks to human
                resources ability which oriented on quality policy. PT. EMKA
                JAYA MANDIRI continue to give a very good and fast progress
              </div>
              <Link to="/about">See more</Link>
            </div>
            <div>
              <div className="title">Our Values</div>
              <div>
                Our values are trust, integrity, human resources, professional
                standard & skills
              </div>
            </div>
            <div>
              <div className="title">Vision & Mission</div>
              <div>
                PT EMKA JAYA MANDIRI is being calculated, trusted, and ahead
                because of its effort to develop in the expertise fields,
                international standard quality and services that customers need.
              </div>
              <Link to="/about">See more</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section-product">
        <div className="container">
          <div className="title-group">
            <div className="section-title black">
              OUR <span>PRODUCTS</span>
            </div>
            <Link className="more-link" to="/products">
              See all
              <img src={arrowRight} alt="Go" />
            </Link>
          </div>
          <div className="image-group" style={{ marginBottom: 120 }}>
            {allProducts.map((data) => {
              return (
                <div key={data.type} className="image-card home">
                  <Link to={`/products#${encodeURIComponent(data.type)}`}>
                    <img src={data.images[0].url} alt={data.type} />
                    <div className="title"> {data.type}</div>
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="title-group">
            <div className="section-title black">
              OUR <span>FACILITES</span>
            </div>
            <Link className="more-link" to="/facilities">
              See all
              <img src={arrowRight} alt="Go" />
            </Link>
          </div>
          <div className="image-group">
            {facilitiesHome.map((data) => {
              return (
                <div key={data.name} className="image-card home facilities">
                  <Link to={`/facilities#${encodeURIComponent(data.name)}`}>
                    <img src={data.url} alt={data.name} />
                    <div className="title"> {data.name}</div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
        <img src={gear1} className="gear-1" alt="gear" />
        <img src={gear2} className="gear-2" alt="gear" />
      </section>
      <section className="section-customer">
        <div className="container">
          <div className="section-title black">
            OUR <span>CUSTOMERS</span>
          </div>
          <div className="customer-logo">
            {customerList
              .filter((data) => data.image)
              .map((data) => (
                <img
                  key={data.name}
                  src={data.image}
                  title={data.name}
                  alt={data.name}
                />
              ))}
          </div>
        </div>
      </section>

      <Contact />

      <Footer />
    </div>
  );
};

export default Home;
