import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/img/logo.png";
import hamburger from "../assets/img/hamburger.svg";
import cross from "../assets/img/cross.svg";

const menu = [
  {
    title: "Products",
    url: "/products",
  },
  {
    title: "Facilities",
    url: "/facilities",
  },
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Contact Us",
    url: "/contact",
  },
];

const Navbar = (props) => {
  const location = useLocation();
  const [active, setActive] = useState("");
  const [offsetY, setOffsetY] = useState(window.scrollY);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setActive(location.pathname);
    const onScroll = (e) => {
      setOffsetY(window.scrollY);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [location.pathname]);

  return (
    <nav
      className={`${props.scroll && offsetY < 650 ? "scroll" : ""} ${
        open ? "scroll-open" : ""
      }`}
    >
      <Link to="/" className="logo">
        <img src={logo} alt="Logo" />
        PT. EMKA JAYA MANDIRI
      </Link>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className="hamburger"
      >
        <img src={open ? cross : hamburger} alt="menu" />
      </button>
      <div className={`menu ${open ? "open" : ""}`}>
        {menu.map((data) => {
          return (
            <div
              key={data.title}
              className={`link ${
                active.indexOf(data.url) > -1 ? "active" : ""
              }`}
            >
              <Link to={data.url}>{data.title}</Link>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
