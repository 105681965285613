import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Contact from "../components/Contact";
import Header from "../components/Header";

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);
  return (
    <div>
      <Navbar />
      <Header prefix="ABOUT" title="EMKA JAYA MANDIRI" />
      <section className="page-about container">
        <section>
          <h3>History</h3>
          <p>
            PT. EMKA JAYA MANDIRI was founded in 2014, thanks to human resources
            ability which oriented on quality policy. PT. EMKA JAYA MANDIRI
            continue to give a very good and fast progress. The background of
            the company’s establishment is the need of automation industry tools
            and robot supplier consultant which is very important today to
            improve quality, capacity, and also competitiveness of the product.
          </p>
          <p>
            <b>Business Fields</b> <br />
            We have many manufacture solution and also offer robot for
            automation industry system. Today we have some business activity’s
            segments, such as:
            <ul>
              <li>Engineering & Design Process Consultant</li>
              <li>Machining & Manufacture</li>
              <li>
                Distributor & Integrator System of Nachi, OTC, Motoman, ABB’s
                Robots
              </li>
              <li>
                Industrial Machine’s tool maintanance and services Components &
              </li>
              <li>Spare Part supplier</li>
            </ul>
          </p>
          <p>
            PT EMKA JAYA MANDIRI is being calculated, trusted, and ahead because
            of its effort to develop in the expertise fields, international
            standard quality and services that customers need.
          </p>
        </section>
        <section>
          <h3>Vision</h3>
          <p>
            Focus to become company which supplies high quality automation &
            robotic solution (one stop solution)
          </p>
        </section>
        <section>
          <h3>Mission</h3>
          <p>
            To give solution and innovation to customers and also to improve
            quality, quantity, capacity and efficiency of production process
          </p>
        </section>
        <section>
          <h3>Our Values</h3>
          <p>
            Our values are trust, integrity, human resources, professional
            standard & skills
          </p>
        </section>
        <section>
          <h3>Our Motto</h3>
          <p>Industrial and Automation Solution</p>
        </section>
      </section>
      <Contact />
      <Footer />
    </div>
  );
};

export default About;
