import React from "react";

const Contact = ({ page }) => {
  return (
    <section className="section-colums contact">
      <div className="container">
        {page !== "contact" && (
          <div className="section-title">
            CONTACT <span>US</span>
          </div>
        )}
        <div className="contact-content">
          <div>
            <div className="title">Engineering</div>
            <div className="contact-eng">
              <div>
                <div className="name">Juliawan Hamzah</div>
                <div style={{ marginBottom: 4 }}>
                  <a href="tel:0822 3334 4998">0822 3334 4998</a> /{" "}
                  <a href="tel:0858 8147 4849">0858 8147 4849</a>
                </div>
                <div>
                  <a href="mailto:juliawan.ha@gmail.com">
                    juliawan.ha@gmail.com
                  </a>
                </div>
                <div>
                  <a href="mailto:juliawan.ha@emkajayamandiri.com">
                    juliawan.ha@emkajayamandiri.com
                  </a>
                </div>
              </div>
              <div>
                <div className="name">Rangga</div>
                <div style={{ marginBottom: 4 }}>
                  <a href="tel:0813 6410 3641">0813 6410 3641</a>
                </div>
                <div>
                  <a href="mailto:rangga.arizona03@gmail.com">
                    rangga.arizona03@gmail.com
                  </a>
                </div>
                <div>
                  <a href="mailto:rangga.arizona@emkajayamandiri.com">
                    rangga.arizona@emkajayamandiri.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="title">Marketing</div>
            <div className="contact-marketing">
              <div style={{ marginBottom: 16 }}>
                <a href="mailto:marketing.emka@emkajayamandiri.com">
                  marketing.emka@emkajayamandiri.com
                </a>
              </div>
              <div className="name">Bambang A</div>
              <div style={{ marginBottom: 4 }}>
                <a href="tel:0812 9148 0464">0812 9148 0464</a> /{" "}
                <a href="tel:0822 5801 3551">0822 5801 3551</a>
              </div>
              <div>
                <a href="mailto:bambang.apriadi04@gmail.com">
                  bambang.apriadi04@gmail.com
                </a>
              </div>
              <div>
                <a href="mailto:bambang.apriadi@emkajayamandiri.com">
                  bambang.apriadi@emkajayamandiri.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
