import lathe1 from "../assets/img/lathe-1.png";
import lathe2 from "../assets/img/lathe-2.png";
import lathe3 from "../assets/img/lathe-3.png";
import milling1 from "../assets/img/milling-1.png";
import milling2 from "../assets/img/milling-2.png";
import machining1 from "../assets/img/machining-1.png";

export const facilityList = [
  {
    type: "Lathe Machine",
    images: [lathe1, lathe2, lathe3],
  },
  {
    type: "Milling Machine",
    images: [milling1, milling2],
  },
  {
    type: "Machining Machine",
    images: [machining1],
  },
];
