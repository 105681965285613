import cust1 from "../assets/img/cust-1.png";
import cust2 from "../assets/img/cust-2.png";
import cust3 from "../assets/img/cust-3.png";
import cust4 from "../assets/img/cust-4.png";
import cust5 from "../assets/img/cust-5.png";
import cust6 from "../assets/img/cust-6.png";
import cust7 from "../assets/img/cust-7.png";
import cust8 from "../assets/img/cust-8.png";
import cust9 from "../assets/img/cust-9.png";
import cust10 from "../assets/img/cust-10.png";
import cust11 from "../assets/img/cust-11.png";

export const customerList = [
  {
    name: "PT. ASTRA HONDA MOTOR",
    image: cust1,
  },
  {
    name: "PT. KAWASAKI MOTOR INDONESIA",
    image: cust2,
  },
  {
    name: "PT. LG ELECTRONICS INDONESIA",
    image: cust3,
  },
  {
    name: "PT. HANWA STEEL SERVICE INDONESIA",
    image: cust4,
  },
  {
    name: "PT. ASTRA OTOPARTS Tbk DIVISI WINTEQ",
    image: cust5,
  },
  {
    name: "PT. CIPTA NIAGA GAS",
    image: cust6,
  },
  {
    name: "PT. SHINTO KYOGO INDONESIA",
    image: cust7,
  },
  {
    name: "PT. AGEL LANGGENG",
    image: cust8,
  },
  {
    name: "PT. MULTI NITROTAMA KIMIA",
    image: cust9,
  },
  {
    name: "PT. SGC READYMIX INDONESIA",
    image: cust10,
  },
  {
    name: "PT. TOKAI RUBBER OUTO HOSE INDONESIA",
    image: cust11,
  },

  {
    name: "PT. MAPAI INDONESIA CONSTRUCTION",
    image: null,
  },

  {
    name: "PT. GALIH AYOM PARAMESTI",
    image: null,
  },
  {
    name: "PT. INTERMAC",
    image: null,
  },
  {
    name: "PT. KIAT MAHESA WINTOR INDONESIA",
    image: null,
  },

  {
    name: "PT. MITRAPACK ERA MANDIRI",
    image: null,
  },
  {
    name: "PT. SINARMAS SEJAHTERA",
    image: null,
  },
  {
    name: "PT. DENTA MAS MULIA",
    image: null,
  },
  {
    name: "PT. ICHIKOH INDONESIA",
    image: null,
  },
  {
    name: "PT. YAHUTO AUTO",
    image: null,
  },
  {
    name: "PT. ASRY DAYA MANDIRI",
    image: null,
  },
  {
    name: "PT. GRANDIS JAYA MANDIRI",
    image: null,
  },
  {
    name: "PT. VALESTO INDONESIA",
    image: null,
  },
  {
    name: "PT. DYNAPLAST INDONESIA",
    image: null,
  },
  {
    name: "PT. NANBU PLASTIC INDONESIA",
    image: null,
  },
  {
    name: "PT. ELPACK",
    image: null,
  },
];
