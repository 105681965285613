import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { facilityList } from "../utils/facilityList";

const Facilities = () => {
  const [checked, setChecked] = useState([]);
  const location = useLocation();

  console.log(location);

  useEffect(() => {
    if (location.hash) {
      const type = decodeURIComponent(location.hash.substring(1));
      setChecked([type]);
      const tabEl = document.getElementById(type);
      var headerOffset = 120;
      var elementPosition = tabEl.offsetTop;
      var offsetPosition = elementPosition - headerOffset;
      console.log(offsetPosition);
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [location.hash]);

  const toggleMenu = (type) => {
    const checkSelected = checked.includes(type);

    if (checkSelected) {
      setChecked(checked.filter((data) => data !== type));
    } else {
      setChecked([...checked, type]);
    }
  };

  const renderImages = (type, data) => {
    return (
      <div className="type-1-grid ">
        {data.map((img, i) => (
          <div key={type + i} className="image-card type-1 facilities">
            <img src={img} alt={type} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <Header prefix="OUR" title="FACILITIES" />
      <section className="products">
        <div className="container">
          <div className="tabs">
            {facilityList.map((data, i) => {
              return (
                <div className="tab" id={data.type}>
                  <input
                    type="checkbox"
                    checked={checked.includes(data.type)}
                    onChange={() => toggleMenu(data.type)}
                    id={`chck${i}`}
                  />
                  <label className="tab-label" for={`chck${i}`}>
                    <div>{data.type}</div>
                  </label>
                  <div className="tab-content">
                    <div className="divider" />
                    {renderImages(data.type, data.images)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Contact />
      <Footer />
    </div>
  );
};

export default Facilities;
